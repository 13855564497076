@media only screen and (min-width: 767px) {
  #navbar {
    padding: 0.5rem 1.5rem;
    width: 98vw;
  }
}
@media only screen and (max-width: 980px) {
  #pos-absolute {
    font-size: 1.5rem;
    font-weight: 200;
  }
  //nav
  #navbar {
    padding: 0.5rem 1rem;
    .burger-menu {
      margin-right: 1rem;
    }
  }
  //home
  #home p {
    width: 80%;
  }
  //About
  #about .left p {
    width: 100%;
  }
  #about .right img {
    width: 220px;
  }
  //contact
  #contact p {
    width: 80%;
  }

  //work and cards
  .box-1 {
    background-image: url("https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/work-cinefly-site.png");
  }
  .box-2 {
    background-image: url("https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/work-cinefly-portal.png");
  }
  .box-1,
  .box-2 {
    #cinefly-site,
    #cinefly-portal {
      display: none;
    }
    background-repeat: no-repeat;
    background-size: cover;
    .sub-box {
      @include center-absolute;
    }
  }
  .other,
  #design {
    margin: 120px 0;
  }
  .box-1::after,
  .box-2::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: $primary;
    position: absolute;
    top: 0;
    opacity: 0.6;
  }
  .card-list {
    width: 240px;
    margin: 5px;
  }
}
