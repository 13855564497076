@import url("https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap");
@import "./config";
@import "./components";
@import "./tablet";
@import "./mobile";

body {
  background: $primary !important;
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  color: $text-light;
  box-sizing: border-box;
  overflow: hidden;
}

.loading-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    animation-name: rotation;
    animation-duration: 2s;
    width: 30px;
  }
}

@keyframes rotation {
  50% {
    transform: rotate(360deg);
  }
}

#about,
#work,
#contact {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

ul,
ol,
li {
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  padding: none;
  padding-left: 0;
  padding-right: 0;
  padding: none;
}
li {
  list-style-type: none;
}

p {
  font-weight: 200;
  line-height: 1.5em;
}

.container-fluid {
  height: 100vh;
  background-color: $primary;
  background-image: url("https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/bg-3.jpg");
  background-size: cover;
}

.outer-wrapper {
  color: $text-light;
  padding-top: 2rem;
  height: 95vh;
}
.inner-wrapper {
  width: 80%;
  margin: auto;
}

#work {
  margin-top: 4rem;
}

#tech-stacks,
#tech-work,
#section-2-work,
#section-3-work,
#section-4-work {
  list-style-type: square;
  li {
    list-style-type: square;
    font-weight: 200;
  }
}

#navbar {
  background-image: url("https://portfolio-eb.s3.ap-southeast-2.amazonaws.com/bg-3.jpg");
  background-size: cover;
  background-color: rgba($color: $primary, $alpha: 0.5);
  margin: auto;
  position: fixed;
  display: flex;
  justify-content: space-between;
  @include links($link, $visited, $hover, $active, $focus);
  font-family: "Cutive Mono", Arial, Helvetica, sans-serif;
  z-index: 100;
  img {
    width: 25px;
  }
}

.burger-menu {
  display: none;
}
#border-1 {
  border: 1px solid $secondary;
}

//burger menu
.burger-menu,
.btn-active,
.nav {
  z-index: 100;
}
.burger-wrapper {
  padding: 1rem;
}
.burger-menu-btn {
  color: white;
  background: $bg-dark-lighter;
  border: none;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.btn-active {
  background-color: $bg-dark-lighter;
  width: 50vw;
  font-family: "Cutive Mono", Arial, Helvetica, sans-serif;
  z-index: 100;
}
#pos-absolute {
  border: none;
  color: $text-light;
  background: $bg-dark-lighter;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 0.05rem;
  padding-right: 1.25rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
#burger-bars {
  padding-left: 0;
  #bar-2 {
    margin-top: -2px;
  }
}
.burger-bars-item {
  height: 12px;
  width: 20px;
  border-top: 2px solid $text-light;
  border-bottom: 2px solid $text-light;
  border-left: none;
  border-right: none;
}
//footer
.footer-socials {
  position: fixed;
  bottom: 80px;
  left: 0;
  z-index: 5;
  li {
    margin: 5px 0;
  }
}
.footer-socials::after {
  content: "";
  border: 1px solid $secondary;
  position: fixed;
  width: 1px;
  margin-top: 10px;
  height: 82px;
  background: $secondary;
  margin-left: 18px;
}

.social-email {
  position: fixed;
  right: -55px;
  bottom: 190px;
  transform: rotate(90deg);
  font-family: "Cutive Mono", Arial, Helvetica, sans-serif;
}
.social-email::after {
  content: "";
  border: 1px solid $secondary;
  position: fixed;
  width: 80px;
  height: 1px;
  background: $secondary;
  right: 0;
  margin-right: -100px;
  bottom: 27px;
}

.footer-power {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  z-index: 10;
  span {
    color: red;
    opacity: 0.2;
  }
}

.mono-span {
  @include mono;
  color: $secondary;
}

.mono-h1 {
  @include mono-title;
}

//scrollbar
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $primary;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $bg-dark-lighter;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $secondary;
}
