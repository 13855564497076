// Home
#home {
  padding-left: 2rem;
  padding-right: 2rem;
  @include center-absolute;
  h1 {
    span {
      font-weight: 500;
      color: $text-light;
    }
    font-size: 50px;
    font-weight: 300;
    color: $secondary-lower-opacity;
  }
  p {
    line-height: 1.7rem;
  }
  span {
    color: $secondary;
  }
}

// about
#about {
  padding-top: 3rem;

  .left p {
    width: 50%;
  }
  .left ul {
    @include mono;
  }
  .right {
    position: relative;
    img {
      width: 320px;
      position: absolute;
      bottom: -100px;
      right: 0;
    }
  }
}
#about h1::after {
  left: 120px;
  top: 30px;
  @include line-width;
}

// Experience
#experience {
  position: relative;
  color: $text-light;
  width: 75%;
  @include center-absolute;
  .mdbContainer {
    padding-top: 10rem;
  }
  h3 {
    font-size: 18px;
  }
  .mono-span {
    font-weight: 300;
  }
}
.scrollspy {
  position: relative;
  height: 40vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}

.nav-pills {
  @include links($link, $visited, $hover, $active, $focus);
  @include mono;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: $nav-pills-link-active-bg;
  color: $nav-pills-link-active-color;
}
.nav-pills .nav-link.active::before {
  content: "";
  width: 7px;
  height: 20px;
  background-color: $active;
  position: absolute;
  left: 5px;
}

#overflow-scroll {
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
#work {
  width: 80%;
  padding-top: 1.5rem;
  @include links($link, $visited, $hover, $active, $focus);
  #feat-1,
  #feat-2 {
    color: $secondary;
  }
  p {
    position: relative;
    div {
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      img {
        width: 15px;
      }
    }
  }
}

.sub-box-1 ul,
.sub-box-2 ul {
  margin-top: -0.5rem;
  padding: 0;
  @include mono;
  flex-wrap: wrap;
  opacity: 0.3;
}
.sub-box-1,
.sub-box-2 {
  z-index: 5;
}

.row {
  height: 75%;
  position: relative;
  #cinefly-site,
  #cinefly-portal {
    position: absolute;
    width: 520px;
  }
  #cinefly-site {
    left: 70px;
  }
  .sub-box-1,
  .sub-box-2 {
    position: absolute;
  }
  .sub-box-1 {
    right: 100px;
    top: 190px;
  }
  #cinefly-portal {
    right: 70px;
    top: 70px;
  }
  .sub-box-2 {
    position: absolute;
    left: 120px;
    top: 300px;
  }
}

//CARDS
.card-list {
  width: 300px;
  margin: 10px;
}
.card {
  background-color: $card-bg;
}
.card-body img {
  width: 100%;
  height: 100%;
}

.mute {
  font-size: 14px;
  @include mono;
  color: $secondary-lower-opacity;
}

.icon-folder,
.icon-design-folder {
  width: 50px;
}
.icon-link {
  width: 20px;
}
.other,
#design {
  margin: 160px 0;
}
#design {
  padding-bottom: 5rem;
}
.map-card {
  flex-wrap: wrap;
  h3 {
    font-size: 18px;
  }
  #work-card-footer {
    @include mono;
    padding: 0 0;
    flex-wrap: wrap;
    font-size: 13px;
  }
}

//Design
.map-design {
  flex-wrap: wrap;
  .card-list {
    overflow: hidden;
    .card-body {
      position: relative;
      background-size: cover;
      padding: 0 !important;
      height: 280px;
      overflow: hidden;
    }
    .card-body img {
      width: 100%;
      height: 100%;
    }
    .card-body div {
      position: absolute;
      top: 0;
      left: 0;
      background: $bg-dark;
      opacity: 0.5;
      width: 100%;
      height: 100%;
    }
  }
}

#contact {
  @include center-absolute;
  text-align: center;
  margin: auto;
  ul {
    padding-left: 0;
    justify-content: center;
    align-items: center;

    li,
    img {
      width: 30px;
      margin-right: 8px;
    }
  }
  p {
    width: 520px;
    margin: auto;
  }
  .btn-email {
    color: $text-light;
    border: 1px solid $secondary;
    border-radius: 0;
    padding: 0.5rem 2rem;
  }
}
