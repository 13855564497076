@media only screen and (max-width: 620px) {
  //home
  #home {
    padding-left: 0;
    padding-right: 0;
    p {
      width: 100%;
    }
  }
  //about
  #about .right img {
    display: none;
  }
  //contact
  #contact p {
    width: 100%;
  }

  //work and cards
  .box-1,
  .box-2 {
    .sub-box-1,
    .sub-box-2 {
      width: 100%;
    }
  }
  .other,
  #design {
    margin: 80px 0;
  }
  .map-card {
    ul,
    li {
      padding: 0 !important;
    }
  }
  .map-card,
  .card-list,
  .map-design {
    width: 100%;
    padding-left: 0;
  }
  .footer-power {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  #about img {
    display: none;
  }
  //footer
  .footer-socials,
  .social-email {
    display: none;
  }

  //nav
  #navbar {
    padding: 0.5rem 0;
    width: 98vw;
    #border-1 {
      margin-top: 1.2rem;
    }
    .nav {
      flex-direction: column;
      position: relative;
      .burger-menu {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
      }
      .nav-item {
        display: none;
      }
    }
    .margin-space {
      margin-top: 2rem;
    }
  }
  #burger-menu {
    z-index: 100;
  }
  .burger-menu-btn {
    padding: 0.8rem 1rem;
  }
}

@media only screen and (max-width: 580px) {
  #navbar {
    width: 96vw;
    .nav-link {
      font-size: 18px;
      font-weight: 200;
      line-height: 2rem;
    }
  }

  #home h1 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 414px) {
  #about,
  #work,
  #experience {
    width: 95%;
  }
  .loading-wrapper {
    max-height: 80vh;
  }
}

@media only screen and (max-height: 900px) {
  #experience {
    position: fixed;
    .mdbContainer {
      padding-top: 2.5rem;
    }
  }
}

@media only screen and (max-height: 1180px) {
  #experience {
    position: fixed;
    padding-left: 0;
    padding-right: 0;
    .mdbContainer {
      padding-top: 0;
    }
  }
}

@media only screen and (max-height: 667px) {
  #experience .mdbContainer {
    padding-top: 1.6rem;
  }
}
