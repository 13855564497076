$primary: #1d1d1d !default;
$secondary: #008ee6 !default;
$secondary-lower-opacity: #a2c2d6;

$info: #dfeffa;
$bg-dark: #0d0d0d;
$bg-dark-lighter: #1d1d1d;
// $text-light: #dfeffa;
$text-light: #c5c5c5;
$text-dark: #2b2b2b;

$light: #dfeffa;
$dark: #212529;
$danger: red;
$warning: green;
$success: green;

$link: $secondary;
$visited: #57ade3;
$hover: #a2c2d6;
$active: #57ade3;
$focus: #57ade3;

$component-active-bg: transparent !important;
$nav-pills-link-active-bg: $component-active-bg;

$component-active-color: $active !important;
$nav-pills-link-active-color: $component-active-color;

$card-bg: $bg-dark-lighter !important;
$card-color: $text-light;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

.primary {
  color: $text-light;
  background: $primary;
}

@mixin mono {
  font-family: "Cutive Mono", Arial, Helvetica, sans-serif;
}

@mixin mono-title {
  font-family: "Cutive Mono", Arial, Helvetica, sans-serif;
  color: $secondary;
  font-size: 32px;
}

@mixin links($link, $visited, $hover, $active, $focus) {
  a {
    color: $link;
    text-decoration: none;
    &:visited {
      color: $visited;
    }
    &:hover {
      color: $hover;
    }
    &:active {
      color: $active;
    }
    &:focus {
      color: $focus;
    }
  }
}

@mixin center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.bg-dark-lighter {
  background-color: $bg-dark-lighter;
}

@mixin line-title {
  content: "";
  background-color: $text-light;
  position: absolute;
}

@mixin line-width {
  width: 200px;
  height: 1px;
  opacity: 0.2;
}
